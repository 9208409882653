.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    /*width: 100%;*/
    justify-content: space-between;
    align-items: center;
    /*align-content: stretch;*/
    /*background-color: aquamarine;*/
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /*background-color: antiquewhite;*/
}

.messageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: antiquewhite;*/
}

.dashProgressBar {
    display: flex;
    flex: 1;
    max-height: 100px;
}

.buttonsContainer {
    display: flex;
    flex: 1;
    max-height: 167px;
    flex-direction: column;
    align-items: center;
    /*background-color: #61dafb;*/
}

.buttonContainer {
    width: 444px
}

.footer {
    width: 100%;
    margin-bottom: 56px;
}
