.root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
}

.modal {
  background-color: rgba(249,249,249,1);
  max-width: 60rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 100;
  padding: 5rem 1rem;
}

.buttonsBlock {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

@media (min-width: 900px) {
  .buttonsBlock {
    flex-direction: row;
  }
  .modal {
    padding: 5rem 10rem;
    margin-top: 30vh;
    border-radius: 4px;
  }
}

.textBlock {
  text-align: center;
  font-family: Roboto Condensed;
  color: #333333;
  font-size: 32px;
  font-weight: 400;
}