/* barlow-semi-condensed-200 - latin */
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 200;
    src: url('./assets/fonts/barlow-semi-condensed-v6-latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/barlow-semi-condensed-v6-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-200.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-200.svg#BarlowSemiCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-semi-condensed-regular - latin */
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-regular.svg#BarlowSemiCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-semi-condensed-700 - latin */
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/barlow-semi-condensed-v6-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700.svg#BarlowSemiCondensed') format('svg'); /* Legacy iOS */
}
/* barlow-semi-condensed-700italic - latin */
@font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/barlow-semi-condensed-v6-latin-700italic.svg#BarlowSemiCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-300 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/roboto-condensed-v19-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/roboto-condensed-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/roboto-condensed-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/roboto-condensed-v19-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/roboto-condensed-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/roboto-condensed-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin */
@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/roboto-condensed-v19-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./assets/fonts/roboto-condensed-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/roboto-condensed-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/roboto-condensed-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/roboto-condensed-v19-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/lato-bold.woff2') format('woff2'),
    url('./assets/fonts/lato-bold.woff') format('woff'),
    url('./assets/fonts/lato-bold.ttf') format('truetype'),
    url('./assets/fonts/lato-bold.eot') format('embedded-opentype')
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/lato-regular.woff2') format('woff2'),
    url('./assets/fonts/lato-regular.woff') format('woff'),
    url('./assets/fonts/lato-regular.ttf') format('truetype'),
    url('./assets/fonts/lato-regular.eot') format('embedded-opentype')
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/lato-medium.eot') format('woff2'),
    url('./assets/fonts/lato-medium.woff') format('woff'),
    url('./assets/fonts/lato-medium.ttf') format('truetype'),
    url('./assets/fonts/lato-medium.eot') format('embedded-opentype')
}

@font-face {
    font-family: 'RobotoFlex';
    src: url('./assets/fonts/RobotoFlex-VariableFont_GRAD,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.ttf')
      format('woff2-variations');
    font-weight: 125 950;
    font-stretch: 75% 125%;
    font-style: oblique 0deg 20deg;
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter-VariableFont_slnt,wght.ttf')
      format('woff2-variations');
    font-weight: 125 950;
    font-stretch: 75% 125%;
    font-style: oblique 0deg 20deg;
  }

@font-face {
    font-family: orpheus-pro;
    src: url(https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/l?subset_id=2&fvd=n4&v=3) format("woff2"),
    url(https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/d?subset_id=2&fvd=n4&v=3) format("woff"),
    url(https://use.typekit.net/af/06e5a6/00000000000000007735a59d/30/a?subset_id=2&fvd=n4&v=3) format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/Roboto-Black.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('./assets/fonts/Roboto-BlackItalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('./assets/fonts/Roboto-Italic.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/Roboto-Light.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('./assets/fonts/Roboto-Thin.ttf') format('truetype')
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 100;
    src: url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype')
}

html, body, #root {
    height: 100%;
    margin: 0;
    overflow: auto;
}
