.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 50px;
    /*align-content: stretch;*/
}

.viewYouJustBurned {

}
