.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 40px 50px;
}

.row {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.row2 {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
}

.title {
    /*display: flex;*/
    /*flex: 0;*/
    /*align-items: flex-start;*/
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    padding-bottom: 36px;
}

.buttonsContainer {
    display: flex;
    flex: 1;
    max-height: 167px;
    flex-direction: column;
    align-items: center;
    /*background-color: #61dafb;*/
}

.removeAccountButton {
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    color: red;
    font-size: 14px;
  }
