.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.titleContainer {
    text-align: center;
    font-family: 'Barlow Semi Condensed';
    font-weight: 400;
    font-size: 48px;
    text-ttransform: uppercase;
    margin-bottom: 40px;
}


.logo {
    width: 300px;
    height: 50px;
}

.feedbackMessageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-height: 300px;
    /*background-color: beige;*/
}

.thanks {
    font-family: 'Roboto Condensed';
    font-weight: 400;
    font-size: 32px;
}

.authInputContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.signInButtonContainer {
    display: flex;
    flex: 0;
    margin-top: 40px;
}

.messageContainer {
    text-align: center;
    padding-top: 20px;
    height: 50px;
}

.errorMessage {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 12px;
}

.contentWrap {
    display: flex;
    flex-direction: column;
    padding: 26px 22px;
    margin-top: 30px;
    width: 90vw;
    height: 70vh;
    border-radius: 12px;
    background-color: white;
}

.titleText {
    font-weight: 600;
    margin-bottom: 28px;
}

.emailLink {
    color: #1A1A1A;
}
