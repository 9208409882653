.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}


.titleContainer {
    color: #1C1C1E;
    text-align: center;
    font-family: 'RobotoFlex';
    font-weight: 400;
    font-size: 48px;
    /* text-transform: uppercase; */
    margin-bottom: 40px;
}


.logo {
    width: 300px;
    height: 50px;
}

.authInputContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.passwordInput {
    margin-top: 30px
}

.confirmPasswordInput {
    margin-top: 20px
}

.signInButtonContainer {
    display: flex;
    flex: 0;
    margin-top: 40px;
}

.messageContainer {
    text-align: center;
    padding-top: 20px;
    height: 50px;
}

.errorMessage {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 12px;
}
