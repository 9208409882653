.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    /*align-content: stretch;*/
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    height: 90vh;
}

.content_white {
    margin: 0 50px 38px;
    border-radius: 20px;
    background-color: white;
}

.footer {
    width: 100%;
    margin-bottom: 56px;
}
