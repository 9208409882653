.agreement {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  left: 0;
  top: 0;
  overflow-y: hidden;
  background-color: rgba(255,255,255, 0.5);
  display: flex;
}

.agreementBlock {
  background-color: white;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex: 1;
  justify-content: space-between;
  border-radius: 15px;
  overflow: hidden;
}

.agreementTitle {
  margin-top: 2rem; 
  margin-bottom: 0;
  color: #ff0000;
  text-align: center;
}

.agreementFrame {
  display: flex; 
  justify-content: space-between;
  width: 100%;
  background-color: hsla(0, 0%, 49.3%, 0.38);
  margin-top: 1rem;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.frame {
  width: 100%;
  flex: 1;
  overflow: scroll;
  min-height: 480px;
}

@media (min-width: 481px) {
  .frame {
    min-height: 456px;
  }
}

@media (min-width: 511px) {
  .frame {
    min-height: 832px;
  }
}

@media (min-width: 642px) {
  .frame {
    min-height: 640px;
  }
}

@media (min-width: 931px) {
  .frame {
    min-height: 448px;
  }
}

@media (min-width: 600px) {
  .agreementBlock {
      margin: 1rem 5% 1rem 5%;
  }
}

.agreementButtonsContainer {
  display: flex;
  justify-content: center;
  margin-right: 5%;
  margin-left: 5%;
  pointer-events: all;
  gap: 2rem;
  padding-bottom: 2rem;
  width: 100%;
}

.agreementButtonContainer {
  width: 444px;
  display: flex;
  justify-content: center;
  align-items: center;
}
