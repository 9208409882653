.input {
    font-size: 20px;
    font-family: Roboto, serif;
    border-radius: 12px;
    padding: 11px;
    min-width: 300px;
}

.inputLight {
    background-color: #FFF;
    color: #434343;
    border: 1px solid #807F84;
    outline: none;
}

.inputLight:active,
.inputLight:hover,
.inputLight:focus {
    outline: 1px solid #807F84;
}

.inputDark {
    background-color: transparent;
    color: #FFF;
    border: 1px solid #FFF;
    outline: none;
}

.inputDark:active,
.inputDark:hover,
.inputDark:focus {
    outline: 1px solid #FFF;
}

.inputDark::placeholder {
    color: #FFF;
}
