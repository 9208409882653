.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}


.logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 120px 0;
}


.logo {
    width: 300px;
    height: 50px;
}

.authInputContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.passwordInput {
    margin-top: 20px
}

.authLinksContainer {
    display: flex;
    flex-direction: row;
    flex: 0;
    justify-content: space-between;
    padding: 10px 0;
}


.authLink {
    color: #1C1C1E;
    font-family: "RobotoFlex", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
}

.signUpContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.signUpLink {
    color: #1C1C1E;
    font-family: "RobotoFlex", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
}

.signInButtonContainer {
    display: flex;
    flex-direction: column;
    flex: 0;
    margin-top: 20px;
    margin-bottom: 70px;
}

.messageContainer {
    text-align: center;
    padding-top: 20px;
}

.errorMessage {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 12px;
}
