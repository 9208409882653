.checkboxGoogle {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    margin-right: 10px;
    position: relative;
    vertical-align: middle;
    font-size: 14px;
    user-select: none;
}
.checkboxGoogle .checkboxGoogleSwitch {
    display: inline-block;
    width: 36px;
    height: 14px;
    border-radius: 20px;
    position: relative;
    top: 7px;
    vertical-align: top;
    background: #ffffff00;
    transition: .2s;
    border: 2px solid #d2d2d2
}
.checkboxGoogle .checkboxGoogleSwitch:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    left: -3px;
    background: #bbbbbb;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
    transition: .15s;
}
.checkboxGoogle input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.checkboxGoogle input[type=checkbox]:checked + .checkboxGoogleSwitch {
    background: #b8c1c5;
}
.checkboxGoogle input[type=checkbox]:checked + .checkboxGoogleSwitch:before {
    background: #323232;
    transform:translateX(18px);
}

/* Hover */
.checkboxGoogle input[type="checkbox"]:not(:disabled) + .checkboxGoogleSwitch {
    cursor: pointer;
    border-color: rgba(0, 0, 0, .3);
}

/* Active/Focus */
.checkboxGoogle input[type="checkbox"]:not(:disabled):active + .checkboxGoogleSwitch:before,
.checkboxGoogle input[type="checkbox"]:not(:disabled):focus + .checkboxGoogleSwitch:before {
    animation: checkbox-active-on 0.5s forwards linear;
}
@keyframes checkbox-active-on {
    0% {
        box-shadow: 0 0 0 0 rgba(212,212,212, 0);
    }
    99% {
        box-shadow: 0 0 0 10px rgba(212,212,212, 0.5);
    }
}

.checkboxGoogle input[type="checkbox"]:not(:disabled):checked:active + .checkboxGoogleSwitch:before,
.checkboxGoogle input[type="checkbox"]:not(:disabled):checked:focus + .checkboxGoogleSwitch:before {
    animation: checkbox-active-off 0.5s forwards linear;
}
@keyframes checkbox-active-off {
    0% {
        box-shadow: 0 0 0 0 rgba(154,190,247, 0);
    }
    99% {
        box-shadow: 0 0 0 10px rgba(184,193,197, 0.5);
    }
}

/* Disabled */
.checkboxGoogle input[type=checkbox]:disabled + .checkboxGoogleSwitch {
    filter: grayscale(60%);
    border-color: rgba(0, 0, 0, .1);
}
.checkboxGoogle input[type=checkbox]:disabled + .checkboxGoogleSwitch:before {
    background: #eee;
}


.label {
    margin-left: 4px;
}
