.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    min-height: 100%;

    
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    /*background-color: cadetblue;*/
}

.header {
    display: flex;
    height: 88px;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
}

.headerRightCorner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 25px;
}

.footer {
    display: flex;
    height: 100px;
    /*background-color: aquamarine;*/
}