.root {
  position: absolute;
  padding: 1rem 2rem;
  margin-top: 110px;
  margin-right: 20px;
  background-color: #bbbbbb;
  border-radius: 1rem;
  max-width: 25rem;
  top: 0;
  right: 0;
  transition: opacity 1s ease-in;
}

.text {
  color: #333333;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 1rem;
}

.image {
  width: 2rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #000000;
}

.button {
  border-radius: 1rem;
  border: none;
  color: #fff;
  padding: 8px 15px;
  background-color: #333333;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}