.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    /*width: 100%;*/
    justify-content: space-between;
    align-items: center;
    /*align-content: stretch;*/
    /*background-color: aquamarine;*/
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /*background-color: antiquewhite;*/
}

.messageContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
}

.subMessageSpace {
    display: flex;
    flex-direction: column;
    flex: 1
}

.dashProgressBar {
    display: flex;
    flex: 1;
    max-height: 100px;
}

.buttonsContainer {
    display: flex;
    flex: 1;
    width: 444px;
    max-height: 167px;
    flex-direction: column;
    align-items: center;
    /*background-color: #61dafb;*/
}

.footer {
    width: 100%;
    margin-bottom: 31px;
}

.startButtons{
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 22px;
    font-weight: 700;
    font-size: 22px;
    background: inherit;
    color: black;
    border:black;
    border-color: black;
}
